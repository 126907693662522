<template>
  <div class="zheCommonUse">
    <div class="zheCommonUse-t">
      <div class="zheCommonUse-tt">
        <div class="h3-btn">智慧通行</div>
        <div class="community">{{ userInfo.chosenSpacePath }}</div>
        <div class="identity" v-if="userInfo.identity">
          {{ relationArrayMap[userInfo.identity] }}
        </div>
      </div>
      <div class="zheCommonUse-tc">
        <div class="code">
          <vue-qr :text="postData.qrCode" :size="size" :dotScale="1"></vue-qr>
        </div>
        <div class="opera">
          <div class="img"><img src="./img/shuaxin.png" alt="" /></div>
          <div class="txt">二维码自动<span @click="refresh">刷新</span></div>
        </div>
        <div class="time">{{ postData.time }}</div>
      </div>
      <div class="zheCommonUse-tb">
        <div class="item icon1" @click="selectIcon">
          <div class="item-t"><img src="./img/icon1.png" alt="" /></div>
          <div class="item-b">业主认证</div>
        </div>
        <div class="item icon2" @click="selectIcon">
          <div class="item-t"><img src="./img/icon2.png" alt="" /></div>
          <div class="item-b">住户授权</div>
        </div>
        <div class="item icon3" @click="selectIcon">
          <div class="item-t"><img src="./img/icon3.png" alt="" /></div>
          <div class="item-b">访客授权</div>
        </div>
      </div>
    </div>
    <div class="zheCommonUse-b">
      <p>本服务由浙江政务服务网、浙江省住房和城乡建设厅提供</p>
      <p>服务咨询热线：<span>0571-89892112</span></p>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import { userInfoUrl } from "./api";
import { relationArrayMap } from "./map";
export default {
  name: "zheCommonUse",
  data() {
    return {
      size: "",
      relationArrayMap,
      userInfo: {},
      postData: {
        qrCode:
          String(new Date().getTime()) +
          "04A8336ED047A8EB4EEB644C02DC46883BE70E9634CE0EAFB8D9D47D51F1A0718942D0C4C5DB17EE15E3841900B3A1A4FDDCBADFDF63FCA2AC1CDF4D269C6251142FC7771AB6EBDA5E8F8C7C92A21CEB0DE0595BEC56148DF80CC978EB019BF3C24810F0C13CEEB398E76724635B2FDB918A18FA16B1558D201BA11089AB350868C4C4C31309BBB1DC2160D80E6CE9880684EE744DD5BDEC883049AA5EFF4525609B7F",
        time: "",
        timer: null,
      },
    };
  },
  components: { vueQr },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  created() {
    this.size = (document.documentElement.clientWidth / 375) * 222;
    this.getUserInfo();
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(this.getTime, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    selectIcon() {
      this.$toast({ message: "暂未开放", duration: 1000 });
      return;
    },
    getTime() {
      var time = new Date(); //获取系统当前时间
      var year = time.getFullYear();
      var month = time.getMonth() + 1;
      var date = time.getDate(); //系统时间月份中的日
      var day = time.getDay(); //系统时间中的星期值
      var hour = time.getHours();
      var minutes = time.getMinutes();
      var seconds = time.getSeconds();
      if (month < 10) {
        month = "0" + month;
      }
      if (date < 10) {
        date = "0" + date;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      this.postData.time =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        seconds;
    },
    refresh() {
      this.postData.qrCode =
        String(new Date().getTime()) +
        "04A8336ED047A8EB4EEB644C02DC46883BE70E9634CE0EAFB8D9D47D51F1A0718942D0C4C5DB17EE15E3841900B3A1A4FDDCBADFDF63FCA2AC1CDF4D269C6251142FC7771AB6EBDA5E8F8C7C92A21CEB0DE0595BEC56148DF80CC978EB019BF3C24810F0C13CEEB398E76724635B2FDB918A18FA16B1558D201BA11089AB350868C4C4C31309BBB1DC2160D80E6CE9880684EE744DD5BDEC883049AA5EFF4525609B7F";
    },
    // 获取用户信息
    getUserInfo() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(userInfoUrl, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
            if (this.userInfo.chosenSpacePath) {
              this.userInfo.chosenSpacePath =
                this.userInfo.chosenSpacePath.split("-")[0];
            }
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.zheCommonUse {
  min-height: 100vh;
  background: url("./img/back.png") no-repeat;
  background-size: 100% 100%;
  padding: 62px 28px 0;
  box-sizing: border-box;
  position: relative;
  .zheCommonUse-t {
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(149, 149, 149, 0.5);
    border-radius: 16px;
    padding: 0 36px;
    .zheCommonUse-tt {
      padding: 28px 0;
      border-bottom: 2px solid #dbdbdb;
      .h3-btn {
        width: 302px;
        height: 90px;
        background: #1c7eed;
        border-radius: 45px;
        font-size: 48px;
        font-weight: 600;
        color: #ffffff;
        line-height: 90px;
        margin: 0 auto;
        text-align: center;
      }
      .community {
        font-size: 60px;
        text-align: center;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 84px;
        margin: 32px auto 12px;
      }
      .identity {
        width: 122px;
        height: 56px;
        background: #e0f0ff;
        border-radius: 45px;
        font-size: 28px;
        font-weight: 600;
        color: #1875d7;
        line-height: 56px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
  .zheCommonUse-tc {
    padding: 70px 0 48px;
    border-bottom: 2px solid #dbdbdb;
    .code {
      width: 444px;
      height: 444px;
      margin: 0 auto;
    }
    .opera {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 60px auto 14px;
      .img {
        width: 28px;
        height: 30px;
        margin-right: 6px;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        font-size: 28px;
        font-weight: 400;
        color: #9a9a9a;
        line-height: 1;
        span {
          color: rgba(72, 132, 176, 1);
          cursor: pointer;
        }
      }
    }
    .time {
      font-size: 40px;
      text-align: center;
      font-weight: 500;
      color: #9a9a9a;
      line-height: 56px;
    }
  }
  .zheCommonUse-tb {
    padding: 70px 52px 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item {
      margin: 0 auto;
      .item-t {
        width: 70px;
        height: 70px;
        margin: 0 auto 12px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-b {
        font-size: 28px;
        text-align: center;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
      }
    }
  }
  .zheCommonUse-b {
    margin: 162px auto 0;
    padding-bottom: 58px;
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    line-height: 34px;
    text-align: center;
    color: #fff;
    p {
      span {
        color: rgba(2, 24, 165, 1);
      }
    }
  }
}
</style>
