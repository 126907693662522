import { mapHelper } from "@/utils/utils.js";
//身份
const relationArray = [
  {
    value: 0,
    text: "产权人本人",
  },
  {
    value: 1,
    text: "产权人配偶",
  },
  {
    value: 2,
    text: "家属",
  },
  {
    value: 3,
    text: "租客",
  },
  {
    value: 4,
    text: "访客",
  },
  {
    value: 5,
    text: "上门服务人员",
  },
];

const { map: relationArrayMap, setOps: relationArrayOps } =
  mapHelper.setMap(relationArray);

export { relationArrayOps, relationArrayMap };
